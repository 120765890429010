import React, { useState } from "react";
import { Layout } from "../layout/layout";
import emailjs from 'emailjs-com';
import Cart from '../assets/images/cart.png'
import { Link } from "react-router-dom";

export const Contact = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [emailSuccess, setEmailSuccess] = useState("");
    const [errors, setErrors] = useState({
        name: "",
        email: "",
        message: "",
    });

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const validateForm = (form) => {
        const newErrors = {
            name: "",
            email: "",
            message: "",
        };

        if (!form.name.value) newErrors.name = "Please enter your name.";
        if (!form.email.value) newErrors.email = "Please enter your email address.";
        if (!form.message.value) newErrors.message = "Please enter a message.";

        setErrors(newErrors);
        return !Object.values(newErrors).some(error => error);
    };

    const sendEmail = (e) => {
        e.preventDefault();

        if (!validateForm(e.target)) {
            return;
        }

        
        const serviceID = 'service_zgux3qe';
        const templateID = 'template_4gfxaqf';
        const userID = 'MklmVT5pdCOupgdxy';
        
        // const serviceID = 'service_zgux3qe';
        // const templateID = 'template_jfnzpi7';
        // const userID = 'MklmVT5pdCOupgdxy';
        emailjs.sendForm(serviceID, templateID, e.target, userID)
            .then((result) => {
                console.log('Email sent successfully:', result);
                setEmailSuccess("Email sent successfully!");
                openModal();
                e.target.reset();
            }, (error) => {
                console.error('Email send error:', error.text);
            });

    };

    return (
        <Layout active={"contact"}>
            <div className="contact bg-clref">
                <div className="container pt-3 pt-md-5">
                    <div className="row">
                        <div className="col-12 col-md-6 bg-clr70 py-4 px-3 py-md-5 px-md-4">
                            <h1 className="text-white">CONTACT US TODAY!</h1>
                            <form action="https://formspree.io/f/mldrbpqw" method="POST">
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label text-white fw-bold">Your Name (required)</label>
                                    <input type="text" name="name" id="name" className="form-control fw-bold" placeholder="Recipient Name" />
                                    <p className="text-white">{errors.name}</p>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label text-white fw-bold">Your Email (required)</label>
                                    <input type="email" name="email" id="email" className="form-control fw-bold" placeholder="Recipient Email" />
                                    <p className="text-white">{errors.email}</p>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="subject" className="form-label text-white fw-bold">Subject</label>
                                    <input type="text" name="subject" id="subject" className="form-control fw-bold" placeholder="Subject" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="message" className="form-label text-white fw-bold">Your Message</label>
                                    <textarea name="message" id="message" rows={8} className="form-control fw-bold" placeholder="Message" />
                                    <p className="text-white">{errors.message}</p>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <button type="submit" className="btn btn-primary send-btn">SEND</button>
                                </div>
                            </form>
                        </div>
                        <div className="col-12 col-md-6 pt-3 pt-md-4">
                            <img className="img-fluid" src={Cart} alt="Cart" />
                            <div className="p-4">
                                <h4 className="ps-3">LET'S START SOMETHING TOGETHER!</h4>
                                <ul>
                                    <li>Please feel free to contact us if you have questions or would like to find out more information about our services:
                                        <ul>
                                            <li>EHR Implementation &amp; Optimization</li>
                                            <li>Payer IT &amp; Business Operations Support</li>
                                            <li>Real-Time Location Services (RTLS)</li>
                                            <li>Revenue Recovery Services</li>
                                            <li>and <strong><Link to="/service">so much more!</Link></strong></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 py-4 px-lg-0">
                            <h2>LOOKING FOR YOUR NEXT CAREER?</h2>
                            <p>Trizonal Solutions provides experts in area of operations and information technology management consulting services, specifically for the healthcare industry. Our firm delivers “Subject Matter Experts” when it comes to healthcare business needs. We are committed to helping Health Plans, Hospitals and Integrated Delivery Systems, reduce cost. Trizonal Solutions hires only the very best; experienced in assisting healthcare organizations with improving the effectiveness of their operations and information technology infrastructures.</p>
                        </div>
                    </div>
                </div>
                <div
                    className={`modal fade ${isModalOpen ? 'show' : ''}`}
                    id="exampleModalCenter"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden={!isModalOpen}
                    style={{ display: isModalOpen ? 'block' : 'none' }}
                >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">
                                    Email sent successfully!
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    aria-label="Close"
                                    onClick={closeModal}
                                />
                            </div>
                            <div className="modal-body">
                                <h2 className="text-uppercase text-center">Thank you</h2>
                                <p className="text-center">We sent your email! An expert will be in touch soon.</p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn-secondary fnt-14 py-1 px-4 text-uppercase"
                                    onClick={closeModal}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
