import * as React from "react";
import { Layout } from "../layout/layout";
import '../assets/styles/conponents/service.scss'
import ServiceImage from '../assets/images/service-main.png'

export const Services = () => {
    const [activeTab, setActiveTab] = React.useState("pills-Vendor");

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    return (
        <Layout active={"service"}>
            <section className="bg-clref">
                <div className="container pt-4 pt-md-5">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <img className="img-fluid" src={ServiceImage} alt="ServiceImage" />
                        </div>
                        <div className="col-12 col-md-6">
                            <h3 className="fw-900 fnt-22 fnt-md-24 fnt-fly pt-3 pt-md-0">THE BEST TALENT IN THE HEALTHCARE INDUSTRY</h3>
                            <p className="lh-225 fnt-15">The healthcare professionals at Trizonal Solutions represent the best talent in the industry. Our Associates include professionals who have held “C” level positions, Program Directors and Sr. Project Manager titles, overseeing large complex system integration, conversion, and implementation projects. Many others are specialists and experts in specific areas of health plans and hospital business operations such as EHR/EMR, Claims, Configuration, Billing, Membership, Medical Management, Reporting, Testing, HIE, Revenue Cycle Management, Meaningful Use, Medicaid, Medicare, HIPAA, ICD-10, 5010, and more.</p>
                            <p className="lh-225 fnt-15">Our professional services model provides clients with optimal staffing flexibility and scale-ability to ensure the most qualified resources will not only meet, but exceed your expectations. Our professional services model allows us to engage and disengage SME’s throughout the project as needed to ensure optimal budget and project objectives are achieved. Unlike other firms, we do not and will not engage professionals throughout the project unnecessarily to maximize our profits.</p>

                        </div>
                    </div>
                    <div className="">
                        <p className="lh-225 fnt-15"><span className="cstm-text-blue">Trizonal Solutions</span>&nbsp;focuses on delivering expert consulting services that assist organizations to optimize their Electronic Health Record (EHR) investments through processes that will maximize integration, workflow, and outcomes.</p>
                        <p className="lh-225 fnt-15">Our industry is actively embracing as well as encouraging all providers and payers to implement telehealth solutions. At Trizonal Solutions we are experienced and prepared to help your organization implement, rollout, and support your telehealth objectives.</p>
                        <p className="lh-225 fnt-15">Overall,&nbsp;Trizonal Solutions&nbsp;clients include; Hospitals, IDN’s, HMO’s, PPO’s, TPA’s, Blue Cross and Blue Shield plans, software vendors, and global consulting firm business partners throughout the United States, Latin America, and the Caribbean.</p>
                    </div>
                </div>
                <div id="payers_service" className="container" >
                    <hr />
                    <h2 className="fw-900 fnt-22 fnt-md-24 fnt-fly text-uppercase">PAYERS</h2>
                    <p><b><span className="cstm-text-blue-unitalic">At&nbsp;<span className="cstm-text-blue">Trizonal Solutions</span>, our payer services focus on your core claim and medical management systems.</span></b>&nbsp;We offer expert level knowledge about these core systems to help your organization achieve its objectives and reduce cost. We can help your organization successfully implement and utilize technologies that improve operational efficiency and solve complex member care management issues. View each category below to find out more information.</p>
                    <p>Our portfolio of consulting services includes unique proprietary tools that we bring to our client engagements. These tools deliver knowledge, planning, and structure to ensure project success and avoid budget overruns.</p>
                    <p><strong>See each category below to find out more information:</strong></p>
                </div>
                {/* Tab  */}

                <div className="container py-3 py-md-4 service-tabs">
                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button
                                className={`bg-white tab-btn nav-link fnt-fly fw-bold ${activeTab === "pills-Vendor" ? "active" : ""}`}
                                onClick={() => handleTabClick("pills-Vendor")}
                            >
                                Vendor Claim System Services
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className={`bg-white tab-btn nav-link fnt-fly fw-bold ${activeTab === "pills-implementation" ? "active" : ""
                                    }`}
                                onClick={() => handleTabClick("pills-implementation")}
                            >
                                Implementation Services
                            </button>
                        </li>

                    </ul>

                    <div className="tab-content bg-white" id="pills-tabContent">
                        <div
                            className={`tab-pane fade ${activeTab === "pills-Vendor" ? "show active" : ""
                                }`}
                            id="pills-Vendor"
                            role="tabpanel"
                            aria-labelledby="pills-Vendor-tab"
                        >
                            <div className="p-3">
                                <p>
                                    <b>
                                        <span className="cstm-text-blue-unitalic">At&nbsp;<span className="cstm-text-blue">Trizonal Solutions</span>, our payer services focus on your core claim and medical management systems.</span>
                                    </b> We offer expert level knowledge about these core systems to help your organization achieve its objectives and reduce cost.
                                    We can help your organization successfully implement and utilize technologies that improve operational efficiency and solve complex member care management issues. View each category below to find out more information.
                                </p>
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <ul>
                                            <li>FACETS™</li>
                                            <li>AMISYS</li>
                                            <li>QNXT™</li>
                                            <li>HealthEdge</li>
                                            <li>MHS &amp; MHC</li>
                                            <li>Health Solutions Plus</li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <ul>
                                            <li>Diamond</li>
                                            <li>Peradigm</li>
                                            <li>HealthTrio</li>
                                            <li>EDS</li>
                                            <li>RAMTechnologies</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`tab-pane fade ${activeTab === "pills-implementation" ? "show active" : ""
                                }`}
                            id="pills-implementation"
                            role="tabpanel"
                            aria-labelledby="pills-implementation-tab"
                        >
                            <div className="p-3">
                                <p className="fnt-15 lh-225">New System Implementations and system integration can be complex and financially risky. There is no substitute for experience to help you navigate the roadmap to success. System implementation and integration services are some of our core competencies.</p>
                                <p className="fnt-15 lh-225">We can help your organization save millions of dollars and significantly reduce the cost of complex large scale system implementations such as payer core claim and hospital EMR/EHR applications as well as many others.</p>
                                <p className="fnt-15 lh-225">Our team of professionals offer in depth knowledge and experience with the industry’s leading payer and hospital applications. This expertise can translate into project cost reductions for your organization resulting in millions of dollars over the life of the project. More importantly, our unparalleled expert knowledge of these leading applications will assure the success of your project objectives.</p>
                                <p className="fnt-15 lh-225">We have already achieved this success for other clients. There is no reason why we cannot achieve the same results for you!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}
